import http from '../utils/http';

class UploadService {
  private path: string;

  constructor(path: string) {
    this.path = path;
  }

  getSignedReadUrl(s3Url, filename) {
    return http({
      url: `api/${this.path}/sign-read`,
      method: 'post',
      data: { url: s3Url, filename }
    });
  }

  upload(file: File) {
    return http({
      url: `api/${this.path}`,
      method: 'POST',
      headers: {
        'Content-type': 'application/octet-stream'
      },
      timeout: 0,
      data: file
    });
  }
}

export default new UploadService('uploads');
